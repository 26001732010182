import React from 'react'
import { shape, arrayOf, string, number, object } from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { Label, Grid, Icon, Button, Card } from 'semantic-ui-react'
import Layout from 'components/Layout'

const TagsPage = ({
  location,
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title }
    }
  }
}) => (
  <Layout location={location}>
    <Grid style={{ margin: 0 }} className="ep-padding-12" centered>
      <Grid.Column mobile={16} tablet={12} computer={10}>
        <Helmet title={`${title} - All tags`} />
        <div className="ep-space-between">
          <Link to="/">
            <Button basic icon labelPosition="left">
              <Button.Content visible>Home</Button.Content>
              <Icon name="home" />
            </Button>
          </Link>
        </div>

        <Card style={{ width: 350 }} raised className="ep-mb-section">
          <Card.Content>
            <Card.Header>Tags</Card.Header>
            <Card.Meta>Below you can find each post category by tag</Card.Meta>
            <Card.Description>
              <Label.Group size="large">
                {group.map(tag => (
                  <Link
                    key={tag.fieldValue}
                    to={`/tags/${kebabCase(tag.fieldValue)}/`}
                  >
                    <Label style={{ margin: '0 8px 8px 0' }}>
                      <Icon name="folder" />
                      {tag.fieldValue}
                      <Label.Detail>{tag.totalCount}</Label.Detail>
                    </Label>
                  </Link>
                ))}
              </Label.Group>
            </Card.Description>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid>
  </Layout>
)

TagsPage.propTypes = {
  location: object,
  data: shape({
    allMarkdownRemark: shape({
      group: arrayOf(
        shape({
          fieldValue: string.isRequired,
          totalCount: number.isRequired
        }).isRequired
      )
    }),
    site: shape({
      siteMetadata: shape({
        title: string.isRequired
      })
    })
  })
}

export default TagsPage

export const pageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
